import React from 'react'
import SalePrice from '../common/SalePrice';

export default class AssetMetadata extends React.Component {
  render() {
    const { asset , order} = this.props
    console.log(order);
    return (
      <React.Fragment>
         <a target="_blank" rel="noopener noreferrer" href={asset.openseaLink}>
         <img class="avatar"
            alt="Asset artwork"
            src={asset.imageUrl} />
         </a>
        
   
                    <div class="price"> <SalePrice order={order} /></div>
                    <div class="title">{asset.name}.</div>
                    <div class="info">{asset.description}</div>
           
                   

      </React.Fragment>
    )
  }
}

/*
        <div className="card-body h-25">
          <h5 className="card-title">{asset.name}</h5>
          <p className="card-text text-truncate">
            <a target="_blank" rel="noopener noreferrer" href={asset.openseaLink} className="card-link">{asset.assetContract.name} #{asset.tokenId}</a>
          </p>
        </div>
        */