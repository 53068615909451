import React from 'react';
import PropTypes, { any } from 'prop-types';
import Order from '../Order';
import { OrderSide } from 'opensea-js/lib/types';
import { connectWallet, BOT_ID, CATEGORY, DAPP_LINK } from '../../constants';
import Account from '../Account'

export default class Log extends React.Component {
  static propTypes = {
    seaport: PropTypes.object.isRequired,
    accountAddress: PropTypes.string
  };

  state = {
    bot_id: BOT_ID,
    orders: undefined,
    total: 0,
    side: undefined,
    onlyForMe: false,
    onlyByMe: false,
    onlyBundles: false,
    page: 1,
    assetsWithoutOrders: undefined,
    price_min: '',
    price_max: '',
    params: {},
    wallet: false,
    filters: {
      color: '',
      character: '',
      song: ''
    },
    toggle: {
      color: false,
      character: false,
      song: false
    },
    tab: 'assets',
    buyInProgress: false,
    connected: false
  };

  componentDidMount() {
    this.fetchData({});
  }


  async fetchData(params) {
    var self = this;
    var stored_price_min = this.getCookie('price_min');
    var stored_price_max = this.getCookie('price_max');
    var stored_song = this.getCookie('song');
    var stored_color = this.getCookie('color');
    var stored_character = this.getCookie('character');


    if (stored_price_min) {
      params.price_min = stored_price_min;
      this.state.params.price_min = stored_price_min;
      this.setState({ price_min: stored_price_min })
    }

    if (stored_price_max) {
      params.price_max = stored_price_max;
      this.state.params.price_max = stored_price_max;
      this.setState({ price_max: stored_price_max });
    }

    if (stored_song) {
      params.song = stored_song;
      this.state.params.song = stored_song;
      this.setState({ song: stored_song });
    }

    if (stored_color) {
      params.color = stored_color;
      this.state.params.color = stored_color;
      this.setState({ color: stored_color });
    }

    if (stored_character) {
      params.character = stored_character;
      this.state.params.character = stored_character;
      this.setState({ character: stored_character });
    }

    var link = 'https://api.brn.ai/products/' + BOT_ID + '?category=' + CATEGORY + '&limit=200';

    var query = '';

    if (params.price_min !== undefined) {
      if (params.price_max !== undefined && params.price_max != '') {
        if (params.price_min == '') {
          params.price_min = 0;
        }
        query += '&price=from' + params.price_min + 'to' + params.price_max;
      } else {
        query += '&price=gte' + params.price_min;
      }
    }

    if (params.color !== undefined && params.color != '') {
      query += '&color=^' + params.color + '$';
    }

    if (params.song !== undefined) {
      query += '&song=' + params.song;
    }

    if (params.character !== undefined) {
      query += '&character=' + params.character;
    }

    link += query;


    fetch(link)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.state.assetsWithoutOrders = data.products;
        self.setState({ assetsWithoutOrders: data.products, total: 20 })
      })

    fetch('https://api.brn.ai/products/' + BOT_ID + '/filters?fields=color,character,song,owner&category=' + CATEGORY)
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        self.state.filters = data.filters;
        self.setState({ filters: data.filters })
      })

    const { accountAddress } = this.props;
    if (accountAddress) {
      this.setCookie('wallet', accountAddress, 30);
      var wallet = accountAddress.substring(0, 6) + "..." + accountAddress.slice(-4);
      this.setState({ wallet: wallet });
    } else {
      var stored_wallet = this.getCookie('wallet');
      if (stored_wallet) {
        var wallet = stored_wallet.substring(0, 6) + "..." + stored_wallet.slice(-4);
        this.setState({ wallet: wallet });
      }
    }
  }

  async connect() {
    if (!window.ethereum) {
      window.location.href = "https://metamask.app.link/dapp/" + DAPP_LINK;
      return;
    }

    const { accountAddress } = this.props

    if (!accountAddress) {
      // await connectWallet();
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accountAddress = accounts[0];

      this.setCookie('wallet', accountAddress, 30);

      var wallet = accountAddress.substring(0, 6) + "..." + accountAddress.slice(-4);
      this.setState({ wallet: wallet });

    } else {
      var wallet = accountAddress.substring(0, 6) + "..." + accountAddress.slice(-4);
      this.setState({ wallet: wallet });
    }

  }

  async disconnect() {
    this.setCookie('wallet', '', 30);
    window.location.reload();
  }

  paginateTo(page) {
    this.setState({ orders: undefined, page }, () => this.fetchData({}))
  }

  toggleSide(side) {
    if (this.state.side === side) {
      side = undefined
    }
    this.setState({
      orders: undefined,
      side,
      onlyForMe: undefined
    }, () => this.fetchData({}))
  }

  async toggleForMe() {
    const { accountAddress } = this.props
    if (!accountAddress) {
      await connectWallet()
    }
    const { onlyForMe } = this.state
    this.setState({
      orders: undefined,
      onlyForMe: !onlyForMe,
      onlyByMe: false,
      // Doesn't make sense to show sell orders the user makes
      side: onlyForMe ? undefined : OrderSide.Buy,
    }, () => this.fetchData({}))
  }

  toggleBundles() {
    const { onlyBundles } = this.state
    this.setState({
      orders: undefined,
      onlyBundles: !onlyBundles,
      onlyByMe: false,
      // Only sell-side for now
      side: OrderSide.Sell,
    }, () => this.fetchData({}))
  }

  async toggleByMe() {
    const { accountAddress } = this.props
    if (!accountAddress) {
      await connectWallet()
    }
    const { onlyByMe } = this.state
    this.setState({
      orders: undefined,
      onlyByMe: !onlyByMe,
      onlyForMe: false
    }, () => this.fetchData({}))
  }


  handleChange = (e, field) => {
    this.state.assetsWithoutOrders = null;

    this.state.params[field] = e.target.value
    this.setCookie(field, e.target.value, 7);
    this.setState({ field: e.target.value });
    this.fetchData(this.state.params);

  }

  filterBy = (field, value) => {
    var self = this;

    this.setCookie(field, value, 7);

    if (this.state.params[field] && this.state.params[field] === value) {
      this.state.params[field] = '';
      this.setState({ field: '' });
      this.state.assetsWithoutOrders = null;
      this.setCookie(field, '', 7);

      setTimeout(function () {
        self.fetchData(self.state.params);
      }, 300);

      return;
    }

    this.state.params[field] = value;
    this.setState({ field: value });
    this.state.assetsWithoutOrders = null;

    setTimeout(function () {
      self.fetchData(self.state.params);
    }, 300);
  }

  toggleProperties = (property) => {
    this.state.toggle[property] = !this.state.toggle[property];
    this.setState({ toggle: this.state.toggle });
    this.setCookie('toggle_' + property, this.state.toggle[property], 7);

  }
  goTo = (tab) => {
    this.state.tab = tab;
    this.setState({ tab: tab });
    console.log(this.state);
  }

  setCookie = (name, value, days) => {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie = (name) => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }



  async fulfillOrder(asset) {
    this.setState({ buyInProgress: true });

    if (asset.attributes.pay_with_credit_card) {
      window.location.href = 'https://buy.brn.ai/payment/' + this.state.bot_id + '/' + asset.product_id;
      return;
    }

    //   const { accountAddress } = this.props

    const order = await this.props.seaport.api.getOrder({ side: 1, asset_contract_address: asset.external.data.asset_contract_address, token_id: asset.external.data.token_id });

    try {
      if (!accountAddress) {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        var accountAddress = accounts[0];
      }

      await this.props.seaport.fulfillOrder({ order, accountAddress })
    } catch (error) {
      if (error != 'Error: No wallet address found') {
        this.setState({ buyInProgress: false });
        alert(error);
      }
    }
  }

  render() {
    const { assetsWithoutOrders, filters } = this.state

    return (
      <div class="wrapper">
        <div class="container">
          <header _ngcontent-cfe-c28="" className="app-header">
            <a _ngcontent-cfe-c28="" href="https://www.eqnft.space/" className="logo">
              <img className="logo" _ngcontent-cfe-c28="" src="https://dangerous.eqnft.space/assets/images/nft/min/logo.png" alt="logo" />
            </a>
            <div className="logo-text">
              <div className="slogan">EQNFT - where fans become investors</div>
            </div>

            {this.state.wallet && (
              <div className="wallet">{this.state.wallet} <span onClick={() => this.disconnect()} class="material-icons">cancel</span></div>
            )}

            {!this.state.wallet && (
              <button onClick={() => this.connect()} className="wallet">Connect wallet</button>
            )}

          </header>
          <div className="mobile-filters">
            <div className={"" + (this.state.tab == 'assets' ? "filter-active" : "")} onClick={() => this.goTo('assets')}>Assets</div>
            <div className={"" + (this.state.tab == 'filters' ? "filter-active" : "")} onClick={() => this.goTo('filters')}>Filters</div>
          </div>

          <div className="content">
            {filters != null

              ? <React.Fragment>

                <div className={"filters " + (this.state.tab == 'filters' ? "m-show" : "m-hide")}>
                  <div className="filter-row">
                    <div className="filter-name">Price</div>
                    <div className="filter-val">MIN</div>
                    <input className="filter-input" type="text" defaultValue={this.state.price_min} placeholder="Not set" onKeyUp={(e) => this.handleChange(e, 'price_min')} />
                    <div className="filter-val">MAX</div>
                    <input className="filter-input" type="text" defaultValue={this.state.price_max} placeholder="Not set" onKeyUp={(e) => this.handleChange(e, 'price_max')} />
                  </div>

                  <div className="filter-row">
                    <div className="filter-name">Properties</div>
                    {filters.character && filters.character.length && (
                      <React.Fragment>
                        <div className="filter-val" onClick={() => this.toggleProperties('character')}>Character</div>

                        <div className={(this.state.toggle.character === true ? "f-show" : "f-hide")}>
                          {filters.character.map((character, i) => {
                            return (
                              <React.Fragment>
                                <div className={"filter-option " + (this.state.params.character == character ? "active" : "")} onClick={() => this.filterBy('character', character)}>
                                  {this.state.params.character == character ? (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box_outline_blank
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <span className="fil-text">{character}</span>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>


                      </React.Fragment>
                    )}

                    {filters.color && filters.color.length && (
                      <React.Fragment>
                        <div className="filter-val" onClick={() => this.toggleProperties('color')}>Color</div>
                        <div className={(this.state.toggle.color === true ? "f-show" : "f-hide")}>
                          {filters.color.map((color, i) => {
                            return (
                              <React.Fragment>
                                <div className={"filter-option " + (this.state.params.color == color ? "active" : "")} onClick={() => this.filterBy('color', color)}>
                                  {this.state.params.color == color ? (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box_outline_blank
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <span className="fil-text">{color}</span>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </React.Fragment>
                    )}

                    {filters.song && filters.song.length && (
                      <React.Fragment>
                        <div className="filter-val" onClick={() => this.toggleProperties('song')}>Song</div>
                        <div className={(this.state.toggle.song === true ? "f-show" : "f-hide")}>
                          {filters.song.map((song, i) => {
                            return (
                              <React.Fragment>
                                <div className={"filter-option " + (this.state.params.song == song ? "active" : "")} onClick={() => this.filterBy('song', song)}>
                                  {this.state.params.song == song ? (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box
                                      </span>
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>
                                      <span class="material-icons">
                                        check_box_outline_blank
                                      </span>
                                    </React.Fragment>
                                  )}
                                  <span className="fil-text">{song}</span>
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </React.Fragment>
                    )}
                  </div>

                </div>

              </React.Fragment>
              : <div className="text-center">Loading...</div>
            }

            {assetsWithoutOrders != null

              ? <React.Fragment>
                <div className={"cards " + (this.state.tab == 'assets' ? "m-show" : "m-hide")}>

                  {assetsWithoutOrders.map((asset, i) => {
                    return (
                      <React.Fragment>
                        <div class="card">
                          <a target="_blank" rel="noopener noreferrer" href="#">
                            <img class="avatar"
                              alt="Asset artwork"
                              src={asset.attributes.image} />
                          </a>

                          <div class="price">
                            {asset.price} {asset.attributes.currency}
                          </div>

                          <div class="title">{asset.name}.</div>


                          <div title={asset.attributes.description} class="info">{asset.attributes.description.substr(0, 81)}...</div>

                          {asset.attributes.owner && asset.attributes.owner.username && (
                            <div class="owner">
                              <div className="avt" style={{ backgroundImage: `url(${asset.attributes.owner.image})` }}>
                              </div>
                              <div className="owner-name"><div className="name">{asset.attributes.owner.username}</div><div className="role">owner</div>
                              </div>
                            </div>
                          )}

                          {asset.attributes.price && (
                            <button onClick={() => this.fulfillOrder(asset)} className="btn">Buy{this.state.buyInProgress && (<span>ing</span>)} for {asset.price} {asset.attributes.currency}{this.state.buyInProgress && (<span>...</span>)}</button>
                          )}

                        </div>

                      </React.Fragment>
                    )
                  })}
                </div>
              </React.Fragment>

              : <div className="text-center lll">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

              </div>
            }
          </div>


        </div>
      </div>
    );
  }
}
/*

       {orders && orders.map((order, i) => {
                    return <Order {...this.props} key={i} order={order} />
                  })}

                <div class="card">
                              <a target="_blank" rel="noopener noreferrer" href={asset.permalink}>
                                  <img class="avatar"
                                  alt="Asset artwork"
                                  src={asset.image_url} />
                              </a>


                              <div class="price"> </div>
                              <div class="title">{asset.name}.</div>
                              <div class="info">{asset.description}</div>

                          </div>

                    */